.product-intro-container {
  display: flex;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.product-intro-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.product-intro-container .product-intro-title {
  margin-top: 20px;
}

/* .product-intro-container .product-intro-title::after {
  content: " ";
  width: 50px;
  height: 1px;
  margin: 6px 2px 0;
  display: block;
  background-color: rgb(214, 214, 214);
} */
