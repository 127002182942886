.product-detail-varation {
  padding: 10px 5px;
  border-radius: 8px;
}

.product-detail-varation input[type="radio"] {
  border: 1px;
  width: 1.5em;
  height: 1.5em;
  margin: auto;
}
