/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

.css-b62m3t-container{
    .css-1s2u09g-control{
        background-color: $card-bg;
        border-color: $border-color;
        &:hover {
            border-color: $border-color;
        }
    }
    .css-1pahdxg-control{
        background-color: $card-bg;
        border-color: $border-color;
        box-shadow: none;
        &:hover {
            border-color: $border-color !important;
        }
    }
    .css-26l3qy-menu {
        background-color: $dropdown-bg;
        .css-1n7v3ny-option{
            cursor: pointer;
            background-color: $dropdown-link-hover-bg;
            &:active {
                background-color: $dropdown-link-hover-bg;
            }
        }
    }
    .css-1rhbuit-multiValue{
        background-color: var(--#{$variable-prefix}light);
        
    }
    .css-12jo7m5{
        color:$heading-color;
    }
    .css-6j8wv5-Input,  .css-qc6sy-singleValue{ 
        color:$heading-color;
    }
}


.marketplace-swiper .swiper-button-next, .marketplace-swiper .swiper-button-prev {
    top: 24px;
}


.marquee {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 22px;
    line-height: 2;
}

.marquee div {
    display: block;
    width: 200%;
    height: 30px;
    position: absolute;
    overflow: hidden;
    animation: marquee 35s linear infinite;
}

.marquee span {
    float: left;
    width: 50%;
}

.restaurant-nav {
    .navbar-brand-box{
        display: block;
    }
}


.resturant-home {
    background: rgb(227,234,240);
    background: linear-gradient(180deg, rgba(227, 229, 240, 0.6) 0%, rgba(227, 234, 240, 0) 51.37%);
    height: auto;
    min-height: 100vh;
}

.restaurant-detail {
    height: auto;
    min-height: 100vh;
}

.min-h-100 {
    min-height: 100%;
}
.card-bg {
    background-color: #FAFAFA;
}
@keyframes marquee {
    0% {
        left: 0;
    }

    100% {
        left: -100%;
    }
}


.bg-primary,
.btn-primary,
.form-check-input:checked,
.input-step.step-primary button
{
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.btn-outline-primary {
    border-color: var(--primary-color) !important;
    color: var(--primary-color);
    &.active,
    &:hover{
        color: #fff ;
        background-color: var(--primary-color) !important;
    }
}
.text-primary {
    color: var(--primary-color) !important;
}
.category-card{
    .active { 
        background-color: var(--primary-color); 
        h6 {
            color: #fff;
        }
    }
    .card-body{
        padding: 1rem 0.3rem;
    }

    @media (max-width: 767.98px) {
        h6 {
            font-size: 12px;
        }
      }
}


[data-layout-mode="dark"] {
    .resturant-home {
        background: var(--vz-body-bg);
    }
    .card-bg {
        background-color: rgb(56 65 74 / 35%);
    }
}